<template>
	<div class="blog">
		<div class="banner">
			<!-- <img class="font" src="../assets/news/font.png" alt="" /> -->
			<div class="font">
				{{ $t('blog.AMANews')}}
			</div>
		</div>
		<div class="body">
			<div class="box">
				<div class="item" @click="go(i)" v-for="(item, i) in blogList" :key="i">
					<!-- <div class="tag">NEW</div> -->
					<img :src="locale == 'zh' ? item.cnImg : item.img" alt="" class="img" />
					<div class="title">
						{{ item.title }}
					</div>
					<div class="font" :title="item.topic">
						{{ item.topic }}
					</div>
					<div class="date">
						{{ item.date }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { fetchData } from '@/api/index';

const { t, locale } = useI18n();
import { useRouter } from 'vue-router';

const router = useRouter();
const blogList = ref([
	{
		img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmbTbuRT3EXCVF4Fga8cY78mDV2L37KaVvvGVL7vw68rVA",
		cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmbDszcfgUyVrUHQKjYLwjQi2Mmy4xpMNm65WbMyskvWRu",
		date: '2024/7/11',
		title: t('blog.item1.title'),
		topic: t('blog.item1.topic'),
	},
	{
		img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmTvTrVmsgij42xFkuB7dcNfWSWuhgyM1NBYcERBxHkLAe",
		cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmVMa7AAtv2e621X59ftngsnMkZGeAEcAtYxaQ2LLC4jCD",
		date: '2024/7/13',
		title: t('blog.item2.title'),
		topic: t('blog.item2.topic'),
	},
	{
		img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmbbiyVbR9FhmdFwk98xpVqaTvkb6556PGCyZd8nSFvSXb",
		cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmWK9KVVWhCCsxHTqxvBjLLefuE4huumcei9e14YkK8SqM",
		date: '2024/8/5',
		title: t('blog.item3.title'),
		topic: t('blog.item3.topic'),
	},
	{
		img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmYCGh5NUHe4oECnFg28fZuc99pFb2so2iMUYcyuLsc4N2",
		cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmcW5MVhPbdgw44VwRHwx9VJJVXJAfkd5CXdx61hBPAFgs",
		date: '2024/7/10',
		title: t('blog.item4.title'),
		topic: t('blog.item4.topic'),
	},
	{
		img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmT28fApG9BSK3wXzoYjsUqGeassEsid1EFjjKvwCUoGsJ",
		cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmWHjvUCbCuGxyjWe3eXkTzjpysiaxrghgY9ToG32sUuFP",
		date: '2024/7/12',
		title: t('blog.item5.title'),
		topic: t('blog.item5.topic'),
	},
	{
		img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmVqdaBhZ6Hi6cKpo1BHZoZ64XMzzpTR6eKCvR7VwYjJA1",
		cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmTV56unCyJqSzYXMMVWNPDxTB49rqok99vpbU5gYpjwx1",
		date: '2024/7/19',
		title: t('blog.item6.title'),
		topic: t('blog.item6.topic'),
	},
	{
		img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmSqxWmPaCLodE69Ueb7P9MESvioFYXc3tkAiRYiEr9B75",
		cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmfAX4qkrkJKQR4o7ZMZFD4MUjss8yFHbdbC93kZ5gtgRq",
		date: '2024/7/26',
		title: t('blog.item7.title'),
		topic: t('blog.item7.topic'),
	},
	{
		img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmSF9v4DMFTrnPjUZAqcbcLu85UHsSRpr4RF9G3grQNJyn",
		cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmYotzzLRjkNHG9AtRo52XzvqcNkFNyMF1msnmeGD4VF5G",
		date: '2024/8/3',
		title: t('blog.item8.title'),
		topic: t('blog.item8.topic'),
	},
	{
		img: '',
		date: '',
		title: '',
		topic: '',
	},
]);
const go = (i) => {
	router.push({
		path: '/avaNews',
		query: { type: i },
	});
};
let lang = 0;
if (locale.value == 'zh') {
	lang = 1;
} else {
	lang = 0;
}
// fetchData(lang) // axios请求在这里
// 	.then((res) => {
// 		console.log(res);
// 		blogList.value = res.data;
// 	})
// 	.catch((err) => {
// 		console.log(err);
// 	});
</script>

<style scoped lang="scss">
@media screen and (max-width: 768px) {
}
@media screen and (min-width: 768px) {
	.blog {
		width: 100%;
		overflow: auto;
		height: auto;
		background: #000000;
		color: #ffffff;
		.banner {
			width: 100%;
			height: 300px;
			display: flex;
			justify-content: space-around;
			align-items: center;
			.font {
				width: 538px;
				color: #f5f5f5;
				font-size: 64px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				text-align: center;
			}
		}
		.body {
			width: 100%;
			padding: 60px 240px 50px;
			.box {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				box-sizing: border-box;
				// .item:nth-child(3n) {
				// 	margin-right: 0;
				// }
				.item {
					max-width: 33%;
					width: 448px;
					height: 468px;
					font-size: 14px;
					font-weight: 400;
					color: rgba(101, 106, 111, 1);
					margin-bottom: 26px;
					cursor: pointer;
					// margin-right: 48px;
					box-sizing: border-box;
					img {
						width: 448px;
						height: 252px;
					}
					.title {
						width: 100%;
						font-size: 24px;
						font-weight: 700;
						color: rgba(255, 255, 255, 1);
						margin-bottom: 6px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box; //作为弹性伸缩盒子模型显示。
						-webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
						-webkit-line-clamp: 3; //显示的行
					}
					.date {
						font-size: 22px;
						color: rgba(255, 255, 255, 1);
					}
					.font {
						font-size: 18px;
						width: 100%;
						color: rgba(255, 255, 255, 0.7);
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box; //作为弹性伸缩盒子模型显示。
						-webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
						-webkit-line-clamp: 3; //显示的行
					}
				}
				.item:last-child {
					visibility: hidden !important;
					height: 0 !important;
					border: 0 !important;
					padding: 0 !important;
					margin: 0 !important;
				}
			}
		}
	}
}
</style>
